import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import LayoutType from '../../constants/layout-type';

const BillingEndPage: React.FC = () => {

    useEffect(() => {
        if (typeof window === 'undefined')
            return;

        window.close();
    }, []);

    return (<>
        <Container component="main" maxWidth="lg">
            <Box textAlign="center" pt={13} pb={2} mb={0} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                <Typography component="h1" variant="h1" sx={{ mt: 4, mb: 1, maxWidth: "100%" }}>
                    Billing Portal Closed
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    You can close this tab and return the to Screen Keep Dashboard.
                </Typography>
            </Box>
        </Container>
    </>);
};

export default BillingEndPage;

BillingEndPage.layoutType = LayoutType.DefaultFullWidth;

export const Head: React.FC = () => <title>ScreenKeep - Billing Portal Return</title>;